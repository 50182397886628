import React from 'react';
import {
  Candidate,
  GroupedExperiences,
  PracticeArea,
  Submission,
  SubmissionCandidate,
  Taxonomy,
} from '@axiom/validation';
import { SubmissionCandidateModel } from '@axiom/ui-models';

import { colors } from '../../../theme/axiom-theme';
import { CandidateHighlightExperienceSummary } from '../CandidateHighlightExperienceSummary/CandidateHighlightExperienceSummary';
import { CandidateHighlightExperienceDetails } from '../CandidateHighlightExperienceDetails/CandidateHighlightExperienceDetails';
import { Gutter } from '../../layout/Gutter/Gutter';
import { Card } from '../../layout/Card/Card';
import { Layout } from '../../layout/Layout/Layout';
import { LayoutItem } from '../../layout/Layout/LayoutItem';
import { AxiomLogo } from '../../element/AxiomLogo/AxiomLogo';
import { CandidateHighlightsUtil } from '../../../utils/candidate-highlights-util';
import { ClientEngagementsCandidateHeader } from '../ClientEngagementsCandidateHeader/ClientEngagementsCandidateHeader';
import { CardSection } from '../../layout/Card/CardSection';
import { CandidateDescription } from '../CandidateDescription/CandidateDescription';
import { SmallHeader } from '../../content/SmallHeader/SmallHeader';
import { TalentExperiences } from '../TalentExperiences/TalentExperiences';
import { TalentEducation } from '../TalentEducation/TalentEducation';
import { TalentCertifications } from '../TalentCertifications/TalentCertifications';
import { TalentQualifications } from '../TalentQualifications/TalentQualifications';
import { TalentLanguages } from '../TalentLanguages/TalentLanguages';
import TransparentVectorBkg from '../../../assets/graphics/TransparentVectorTop.svg?url';

export const BioPdfCandidate = ({
  candidate,
  groupedExperiences,
  submission = null,
  practiceAreas,
  isAnonymous = false,
  taxonomy,
  removeTalentImage = false,
  removeLogo = false,
  hideHighlights = false,
  hideLocation = false,
}: {
  practiceAreas: PracticeArea[];
  candidate: Candidate & SubmissionCandidate;
  groupedExperiences: GroupedExperiences[];
  submission?: Submission;
  isAnonymous?: boolean;
  taxonomy?: Taxonomy;
  removeTalentImage?: boolean;
  removeLogo?: boolean;
  hideHighlights?: boolean;
  hideLocation?: boolean;
}) => {
  const candidateDescription = new SubmissionCandidateModel(
    candidate
  ).calculatedDescription();

  const correctHighlightCalculation = taxonomy
    ? CandidateHighlightsUtil.calculateNumberOfHighlights(candidate, submission)
    : CandidateHighlightsUtil.calculateNumberOfSummaryHighlights(candidate);

  return (
    <Gutter bottom="32px">
      <Card name="TALENT_CONTAINER" shadow={null}>
        <CardSection
          background={{
            image: TransparentVectorBkg,
            color: colors?.controlInverse,
            position: 'right top',
            size: 'contain',
          }}
        >
          <Layout position="space-between middle">
            <LayoutItem position="left">
              <ClientEngagementsCandidateHeader
                candidate={candidate}
                isAnonymous={isAnonymous}
                removeTalentImage={removeTalentImage}
                removeLocationToggle={hideLocation}
              />
            </LayoutItem>
            {!removeLogo && (
              <LayoutItem position="right">
                <AxiomLogo name="LOGO" height="47px" />
              </LayoutItem>
            )}
          </Layout>
          <Gutter bottom="24px" />
        </CardSection>
        <CardSection divider>
          {candidateDescription && taxonomy && (
            <Gutter bottom="16px">
              <CandidateDescription
                description={candidateDescription}
                calculatedFirstName={candidate.calculatedFirstName}
              />
            </Gutter>
          )}
          {correctHighlightCalculation > 0 && !hideHighlights && (
            <>
              <Gutter bottom="16px">
                <SmallHeader name="highlights-title-pdf">
                  Highlights
                </SmallHeader>
              </Gutter>
              <CandidateHighlightExperienceSummary
                candidate={candidate}
                submission={submission}
                condensed
              />

              <Gutter bottom="16px" />
              {taxonomy && (
                <CandidateHighlightExperienceDetails
                  candidate={candidate}
                  practiceAreas={practiceAreas}
                  taxonomy={taxonomy}
                />
              )}
            </>
          )}
        </CardSection>

        {groupedExperiences.length > 0 && (
          <>
            <CardSection divider>
              <SmallHeader name="EXPERIENCE_LABEL">Experience</SmallHeader>
            </CardSection>
            <TalentExperiences
              groupedExperiences={groupedExperiences}
              practiceAreas={practiceAreas}
            />
          </>
        )}

        {candidate?.degrees?.length > 0 && (
          <CardSection>
            <Gutter bottom="16px">
              <SmallHeader>Education</SmallHeader>
            </Gutter>
            <TalentEducation candidate={candidate} />
          </CardSection>
        )}
        {candidate?.certifications?.length > 0 && (
          <CardSection divider>
            <Gutter bottom="16px">
              <SmallHeader>Accomplishments</SmallHeader>
            </Gutter>
            <Gutter bottom="16px">
              <TalentCertifications candidate={candidate} />
            </Gutter>
            <Gutter bottom="16px">
              <TalentQualifications candidate={candidate} />
            </Gutter>
          </CardSection>
        )}
        {candidate?.languages?.length > 0 && (
          <CardSection>
            <Gutter bottom="16px">
              <SmallHeader>Languages</SmallHeader>
            </Gutter>
            <TalentLanguages candidate={candidate} />
          </CardSection>
        )}
      </Card>
    </Gutter>
  );
};
