import React from 'react';
import styled from 'styled-components';
import { Radio as SRadio } from 'semantic-ui-react';

import { AttrsHelper } from '../../../sb-helpers/attrs-helper';

const RadioWrapper = styled.div``;

export type RawRadioValueType = string | number | boolean;

export type RawRadioOptionType = string | number | boolean;

export type RawRadioProps = {
  disabled?: boolean;
  displayValue?: string | React.ReactNode;
  id?: string;
  invalid?: boolean;
  mode?: 'radio' | 'tile';
  name: string;
  onChange?: (value?: RawRadioValueType) => void;
  option: RawRadioOptionType;
  value?: RawRadioValueType;
};

export const RawRadio = ({
  disabled = false,
  displayValue = null,
  id,
  invalid = false,
  mode = 'radio',
  name,
  onChange,
  option,
  value = null,
}: RawRadioProps) => {
  const isSelected = value === option;
  const handleRadioClick = () => {
    onChange(option);
  };
  return (
    <RadioWrapper
      data-test={name}
      className={AttrsHelper.formatClassname('stretchable', `mode-${mode}`)}
    >
      <SRadio
        className={AttrsHelper.formatClassname(invalid && 'invalid')}
        disabled={disabled}
        id={id}
        label={
          displayValue && typeof displayValue !== 'string' ? (
            <label>{displayValue}</label>
          ) : (
            displayValue
          )
        }
        name={name}
        onChange={handleRadioClick}
        checked={isSelected}
      />
    </RadioWrapper>
  );
};
