import React, { useState } from 'react';
import {
  GroupedExperiences,
  PracticeArea,
  Submission,
  SubmissionCandidate,
  User,
  Candidate,
} from '@axiom/validation';
import { isSalesUser } from '@axiom/utils';

import { Button } from '../../element/Button/Button';
import { IconButton } from '../../element/Button/IconButton';
import { Layout } from '../../layout/Layout/Layout';
import { LayoutItem } from '../../layout/Layout/LayoutItem';
import { colors } from '../../../theme/axiom-theme';
import { AnonBioProfileStatusUtil } from '../../../utils/anonymous-bio-profile-status';
import { CandidateHighlightsUtil } from '../../../utils/candidate-highlights-util';
import { CandidateHighlightExperienceSummary } from '../CandidateHighlightExperienceSummary/CandidateHighlightExperienceSummary';
import { Gutter } from '../../layout/Gutter/Gutter';
import { Card } from '../../layout/Card/Card';
import { CardHeader } from '../../layout/Card/CardHeader';
import { CardSection } from '../../layout/Card/CardSection';
import { ClientEngagementsCandidateHeader } from '../ClientEngagementsCandidateHeader/ClientEngagementsCandidateHeader';
import { SmallHeader } from '../../content/SmallHeader/SmallHeader';
import { TalentExperiences } from '../TalentExperiences/TalentExperiences';
import { TalentEducation } from '../TalentEducation/TalentEducation';
import { TalentCertifications } from '../TalentCertifications/TalentCertifications';
import { TalentQualifications } from '../TalentQualifications/TalentQualifications';
import { TalentLanguages } from '../TalentLanguages/TalentLanguages';
import TransparentVectorBkg from '../../../assets/graphics/TransparentVectorTop.svg';
import { Paragraph } from '../../content/Paragraph/Paragraph';

export const BioViewCandidate = ({
  candidate,
  groupedExperiences,
  submission = null,
  practiceAreas,
  isAnonymous = false,
  onNavigate = () => {},
  user,
}: {
  practiceAreas: PracticeArea[];
  candidate: Candidate & SubmissionCandidate;
  groupedExperiences: GroupedExperiences[];
  submission?: Submission;
  isAnonymous?: boolean;
  onNavigate?: (hideHighlights?: boolean, hideLocation?: boolean) => void;
  user?: User;
}) => {
  const [showHighlights, setShowHighlights] = useState(true);
  const [showLocationToggle, setShowLocationToggle] = useState(true);
  return (
    <Gutter bottom="8px">
      {!showLocationToggle && (
        <Gutter bottom="8px">
          <Layout position="left middle">
            <Paragraph>
              Talent Location Removed. Undo{' '}
              <IconButton
                name="SHOW_LOCATION_CTA"
                onClick={() => setShowLocationToggle(true)}
                icon="reset"
                variation="minimal"
              />
            </Paragraph>
          </Layout>
        </Gutter>
      )}
      <Card name="TALENT_CONTAINER">
        <CardSection
          background={{
            image: TransparentVectorBkg,
            color: colors?.controlInverse,
          }}
        >
          <Layout position="middle">
            <LayoutItem position="left" fluid>
              <ClientEngagementsCandidateHeader
                candidate={candidate}
                isAnonymous={isAnonymous}
                removeTalentImage
                removeLocationToggle={!showLocationToggle}
                onToggleLocation={() =>
                  setShowLocationToggle(!showLocationToggle)
                }
                isBioView
              />
            </LayoutItem>
            {(!isSalesUser(user) ||
              isAnonymous ||
              (isSalesUser(user) &&
                !AnonBioProfileStatusUtil.statusSet().has(
                  candidate.profileStatus
                ) &&
                candidate.isProfileShared)) && (
              <LayoutItem position="right">
                <Button
                  name="DOWNLOAD_CTA"
                  onClick={() => {
                    onNavigate(!showHighlights, !showLocationToggle);
                  }}
                >
                  Download
                </Button>
              </LayoutItem>
            )}
          </Layout>
        </CardSection>
      </Card>
      <Gutter vertical="8px">
        {!showHighlights && (
          <Layout position="right middle">
            <Paragraph>
              Highlights Removed. Undo{' '}
              <IconButton
                name="SHOW_HIGHLIGHTS_CTA"
                onClick={() => setShowHighlights(true)}
                icon="reset"
                variation="minimal"
              />
            </Paragraph>
          </Layout>
        )}
      </Gutter>
      {CandidateHighlightsUtil.calculateNumberOfSummaryHighlights(candidate) >
        0 &&
        showHighlights && (
          <Card name="highlights-card-bio-view">
            <CardHeader>
              <Layout position="space-between middle">
                <SmallHeader>Highlights</SmallHeader>
                <IconButton
                  name="HIDE_HIGHLIGHTS_CTA"
                  icon="x-mark"
                  variation="minimal"
                  onClick={() => setShowHighlights(false)}
                />
              </Layout>
            </CardHeader>
            <CardSection>
              <CandidateHighlightExperienceSummary
                candidate={candidate}
                submission={submission}
                condensed
              />
            </CardSection>
          </Card>
        )}
      <Gutter bottom="16px" />
      {groupedExperiences.length > 0 && (
        <>
          <Card>
            <CardHeader>
              <SmallHeader>Experience</SmallHeader>
            </CardHeader>
            <TalentExperiences
              groupedExperiences={groupedExperiences}
              practiceAreas={practiceAreas}
            />
          </Card>
          <Gutter bottom="16px" />
        </>
      )}
      {candidate.degrees?.length > 0 && (
        <>
          <Card>
            <CardHeader>
              <SmallHeader>Education</SmallHeader>
            </CardHeader>
            <CardSection>
              <TalentEducation candidate={candidate} />
            </CardSection>
          </Card>
          <Gutter bottom="16px" />
        </>
      )}
      {candidate.certifications?.length > 0 && (
        <>
          <Card>
            <CardHeader>
              <SmallHeader>Accomplishments</SmallHeader>
            </CardHeader>
            <CardSection>
              <Gutter bottom="16px">
                <TalentCertifications candidate={candidate} />
              </Gutter>
              <Gutter bottom="16px">
                <TalentQualifications candidate={candidate} />
              </Gutter>
            </CardSection>
          </Card>
          <Gutter bottom="16px" />
        </>
      )}
      {candidate.languages?.length > 0 && (
        <>
          <Card>
            <CardHeader>
              <SmallHeader>Languages</SmallHeader>
            </CardHeader>
            <CardSection>
              <TalentLanguages candidate={candidate} />
            </CardSection>
          </Card>
          <Gutter bottom="16px" />
        </>
      )}
    </Gutter>
  );
};
