import { bool, number, shape, string, arrayOf } from 'prop-types';

import { opportunityShape } from './opportunities';
import { TagShape } from './tag';

export const experienceShape = shape({
  candidateId: string,
  client: string,
  description: string,
  endDate: string,
  externalOpportunityName: string,
  id: string,
  isAxiom: bool,
  isConfidential: bool,
  locationAddressComponents: shape({}),
  locationLatitude: number,
  locationLongitude: number,
  locationName: string,
  locationPlaceId: string,
  opportunity: opportunityShape,
  opportunityId: string,
  startDate: string,
  tags: arrayOf(shape(TagShape)),
});

export const AXIOM_EXPERIENCE_EDITABLE_FIELDS = [
  'description',
  'endDate',
  'isConfidential',
  'startDate',
  'tags',
];

export const OTHER_EXPERIENCE_EDITABLE_FIELDS = [
  'client',
  'description',
  'endDate',
  'externalOpportunityName',
  'isConfidential',
  'locationAddressComponents',
  'locationLatitude',
  'locationLongitude',
  'locationName',
  'locationPlaceId',
  'startDate',
  'tags',
];
