import React from 'react';
import { Candidate } from '@axiom/validation';

import { Layout } from '../../layout/Layout/Layout';
import { Paragraph } from '../../content/Paragraph/Paragraph';
import { PDFFooter } from '../BiosPdf/BiosPdfStyles';

export const PdfPageTemplateFooter = ({
  pdfProps,
  candidate,
  isAnonymous = false,
}: {
  pdfProps: { pageNum: number; totalPages: number };
  candidate: Candidate;
  isAnonymous: boolean;
}) => {
  const displayName = () => {
    if (isAnonymous && !!candidate.calculatedFirstName) {
      return candidate.calculatedFirstName;
    } else if (!isAnonymous && !!candidate.calculatedDisplayName) {
      return candidate.calculatedDisplayName;
    }
    return 'Axiom Talent';
  };

  return (
    <Layout position="center">
      <PDFFooter>
        <Layout stretched position="space-between">
          <Paragraph name="PDF_FOOTER_TALENT">{displayName()}</Paragraph>
          <Paragraph name="PDF_PAGE_NUMBER">Page {pdfProps.pageNum}</Paragraph>
        </Layout>
      </PDFFooter>
    </Layout>
  );
};
