import { z } from 'zod';
import { IndustryValue, PositionsConst } from '@axiom/const';

export const licensedLawyerValues = {
  Myself: 'Myself',
  SomeoneElse: 'Someone else',
} as const;

export type LicensedLawyer =
  (typeof licensedLawyerValues)[keyof typeof licensedLawyerValues];

export const occupationTypeValues = {
  Lawyer: 'Lawyer',
  'Legal Support': 'Legal support',
} as const;

export type Occupation =
  (typeof occupationTypeValues)[keyof typeof occupationTypeValues];

export const levelOfExperienceValues = {
  ZeroYears: '0+ years',
  FourYears: '4+ years',
  EightYears: '8+ years',
  TwelveYears: '12+ years',
  FifteenYears: '15+ years',
} as const;

export const levelOfExperienceKeys = {
  ZeroYears: 'ZeroYears',
  FourYears: 'FourYears',
  EightYears: 'EightYears',
  TwelveYears: 'TwelveYears',
  FifteenYears: 'FifteenYears',
} as const;

export type LevelOfExperienceKey =
  (typeof levelOfExperienceKeys)[keyof typeof levelOfExperienceKeys];

export type LevelOfExperience =
  (typeof levelOfExperienceValues)[keyof typeof levelOfExperienceValues];

export const positionReservedTypeValues = {
  Reserved: 'Reserved',
  'Not Reserved': 'Not Reserved',
} as const;

export type PositionReserved =
  (typeof positionReservedTypeValues)[keyof typeof positionReservedTypeValues];

export const AccountIntakeSchema = z.object({
  accountId: z.string().uuid(),
  opportunityId: z.string().uuid(),
  licensedLawyer: z.enum(
    Object.keys(licensedLawyerValues) as NonEmptyArray<LicensedLawyer>
  ),
  licensedLawyerState: z.string(),
  occupationType: z.enum(
    Object.keys(occupationTypeValues) as NonEmptyArray<Occupation>
  ),
  levelOfExperience: z.enum(
    Object.keys(levelOfExperienceKeys) as NonEmptyArray<LevelOfExperienceKey>
  ),
  practiceArea: z.string(),
  primaryFocusArea: z.string(),
  otherFocusAreas: z.array(z.string()).nullable(),
  legalSkills: z.array(z.string()),
  generalSkills: z.array(z.string()),
  legalTechSkills: z.array(z.string()).nullable(),
  industry: z
    .enum(Object.keys(IndustryValue) as NonEmptyArray<IndustryValue>)
    .nullable()
    .optional(),
  minBudgetPerHour: z.number(),
  maxBudgetPerHour: z.number(),
  urgency: z.string().nullable(),
  addressState: z.string().nullable(),
  addressCity: z.string().nullable(),
  addressZip: z.string().nullable(),
  addressStreet: z.string().nullable(),
  billingUnitsPerWeek: z.number(),
  billingUnitsPerDay: z.number(),
  description: z.string().nullable(),
  languageId: z.string().uuid().nullable(),
  worksite: z.enum(
    Object.keys(PositionsConst.Worksites) as NonEmptyArray<string>
  ),
  reservedType: z.enum(
    Object.keys(positionReservedTypeValues) as NonEmptyArray<PositionReserved>
  ),
  startDate: z.string(),
  endDate: z.string(),
});
