import React, { useEffect, useRef } from 'react';
import { Candidate, GroupedExperiences, PracticeArea } from '@axiom/validation';

import { StringUtil } from '../../../utils/string-util';
import { PDFExport } from '../../layout/PDFExport/PDFExport';
import { LayoutItem } from '../../layout/Layout/LayoutItem';
import { Layout } from '../../layout/Layout/Layout';
import { Gutter } from '../../layout/Gutter/Gutter';
import { PdfPageTemplateFooter } from '../PdfPageTemplateFooter/PdfPageTemplateFooter';

import { BioPdfFooter } from './BioPdfFooter';
import { BioPdfCandidate } from './BioPdfCandidate';
import { PDFWrapper } from './BiosPdfStyles';

export type BiosTypes = {
  candidate: Candidate;
  groupedExperiences: GroupedExperiences[];
  practiceAreas: PracticeArea[];
  isAnonymous?: boolean;
  clientUrl: string;
  hideHighlights?: boolean;
  hideLocation?: boolean;
};

export const BioPdf = ({
  candidate,
  groupedExperiences,
  practiceAreas,
  isAnonymous = false,
  clientUrl,
  hideHighlights,
  hideLocation,
}: BiosTypes) => {
  const pdfRoot = useRef(null);

  useEffect(() => {
    if (pdfRoot?.current?.save) {
      window.addEventListener('focus', () => window.close());

      setTimeout(() => pdfRoot.current.save(), 500);
    }

    return () => {
      // eslint-disable-next-line unicorn/no-invalid-remove-event-listener
      window.removeEventListener('focus', () => window.close());
    };
  }, [pdfRoot]);

  return (
    <PDFWrapper data-test="HIDE_RENDERING">
      <PDFExport
        pageTemplate={props => (
          <PdfPageTemplateFooter
            pdfProps={props}
            candidate={candidate}
            isAnonymous={isAnonymous}
          />
        )}
        fileName={`${StringUtil.cleanStringForFileName(
          candidate.calculatedFirstName
        )}${
          isAnonymous
            ? ''
            : ` ${StringUtil.cleanStringForFileName(
                candidate.calculatedLastName
              )}`
        } - Axiom`}
        ref={pdfRoot}
        scale={0.5}
        margin={{ top: '0in', right: '0in', bottom: '0in', left: '0in' }}
      >
        <Layout name="BIO_PDF" background="panelLight">
          <LayoutItem fluid>
            <Gutter horizontal="40px" vertical="24px">
              <BioPdfCandidate
                hideHighlights={hideHighlights}
                hideLocation={hideLocation}
                candidate={candidate}
                groupedExperiences={groupedExperiences}
                practiceAreas={practiceAreas}
                isAnonymous={isAnonymous}
                removeTalentImage
              />
              <BioPdfFooter candidate={candidate} clientUrl={clientUrl} />
            </Gutter>
          </LayoutItem>
        </Layout>
      </PDFExport>
    </PDFWrapper>
  );
};
