import React, { FC, useState } from 'react';
import { Opportunity, OpportunityCandidate, Position } from '@axiom/validation';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardSection,
  CondensedMedium,
  Flashy,
  Grid,
  GridColumn,
  GridRow,
  Layout,
  SmallHeader,
  ToastUtil,
  CondensedHeader,
  CondensedLarge,
  Gutter,
} from '@axiom/ui';

import { AppFindCandidateForOppModalStore } from '../../stores/app-find-candidate-for-opp-modal-store';
import {
  FormSchema,
  GetMoreMatchesModal,
} from '../GetMoreMatchesModal/GetMoreMatchesModal';
import { OpportunityCandidateMlRecommendationApi } from '../../api/opportunity-candidate-ml-recommendations-api';
import { OpportunityStore } from '../../stores/opportunity-store';
import { AiDuplicatesMessage } from '../AiDuplicatesMessage/AiDuplicatesMessage';
import { OpportunityLegacyApi } from '../../api/opportunities-legacy-api';

import { OpportunityTalentParentPositionEmpty } from './OpportunityTalentParentPositionEmpty';
import { noMatchesErrorText } from './OpportunityTalentAiMatchControl';
import { OpportunityTalentParentPositionList } from './OpportunityTalentParentPositionList';
import { OTScrollable } from './OpportunityTalentParentPositionStyles';

interface OpportunityTalentParentPositionProps {
  name: string;
  title?: string;
  opportunity: Opportunity;
  candidates: Array<OpportunityCandidate>;
  isFilled?: boolean;
  isTalentPool?: boolean;
  positions?: Array<Position>;
}

export const OpportunityTalentParentPosition: FC<
  OpportunityTalentParentPositionProps
> = ({
  name,
  title,
  opportunity,
  candidates,
  isFilled,
  isTalentPool,
  positions,
}) => {
  const [hasGottenNoRecommendations, setHasGottenNoRecommendations] =
    useState(false);
  const [showGetMoreMatchesModal, setShowGetMoreMatchesModal] = useState(false);
  const [shouldTriggerModal, setShouldTriggerModal] = useState(
    opportunity.hasBeenAIMatched || hasGottenNoRecommendations
  );
  const [savedFilters, setSavedFilters] = useState<FormSchema>({});
  const addTalentOnClick = () => {
    if (isTalentPool) {
      AppFindCandidateForOppModalStore.beginAddingToOpportunity(
        [opportunity.id],
        opportunity.id
      );
    } else {
      AppFindCandidateForOppModalStore.beginAddingToPosition(
        opportunity.id,
        positions,
        name
      );
    }
  };

  const onGetAiMatchesClick = async () => {
    if (shouldTriggerModal) {
      setShowGetMoreMatchesModal(true);
    } else {
      // we're sending the default parameters (first button click)
      const response =
        await OpportunityCandidateMlRecommendationApi.createOpportunityCandidateMlRecommendations(
          { opportunityId: opportunity.id },
          {}
        );
      await OpportunityLegacyApi.refreshOpportunity(opportunity.id);
      setShouldTriggerModal(true);
      // response is null if there is a known server error
      if (response !== null) {
        await OpportunityStore.load(opportunity.id);
        const { data: { candidateIds, opportunityCandidates } = {} } = response;
        if (!candidateIds?.length) {
          ToastUtil.add({
            dismissible: true,
            name: `NOMATCHES`,
            type: 'error',
            children: (
              <>
                <CondensedHeader>Error</CondensedHeader>
                <CondensedLarge>{noMatchesErrorText}</CondensedLarge>
              </>
            ),
          });
          setHasGottenNoRecommendations(true);
          setShouldTriggerModal(false);
        }

        // Pop a toast if talent was already on the opp before the AI match
        const dupes = opportunityCandidates?.filter(
          ({ duplicate }) => duplicate
        );
        if (dupes?.length) {
          ToastUtil.add({
            dismissible: true,
            name: `DUPLICATECAND`,
            type: 'critical',
            children: <AiDuplicatesMessage duplicates={dupes} />,
          });
        }
      }
    }
  };

  return (
    <>
      <Card name={name}>
        <CardHeader>
          <Grid columns={2}>
            <GridRow>
              <GridColumn>
                <Layout
                  direction="horizontal"
                  horizontalGutter="8px"
                  position="middle"
                >
                  <Badge background="gray">{candidates.length}</Badge>
                  <SmallHeader maxLines={1}>{title}</SmallHeader>
                  {isFilled && <Badge background="green">Filled</Badge>}
                </Layout>
              </GridColumn>
              <GridColumn>
                {opportunity.isFulfillmentActive &&
                  isTalentPool &&
                  opportunity.isAiMatchable && (
                    <Layout
                      direction="horizontal"
                      horizontalGutter="8px"
                      position="right middle"
                    >
                      <Button
                        name="GET_AI_MATCHES_BUTTON"
                        icon="add-user"
                        iconPosition="right"
                        onClick={onGetAiMatchesClick}
                      >
                        {opportunity.hasBeenAIMatched
                          ? 'Get More Matches'
                          : 'Get AI Matches'}
                      </Button>
                    </Layout>
                  )}
              </GridColumn>
            </GridRow>
          </Grid>
        </CardHeader>

        <OTScrollable tall={isTalentPool}>
          <CardSection>
            {opportunity.aiUnmatchableMessage &&
              isTalentPool &&
              !opportunity.isAiMatchable && (
                <Gutter bottom="16px">
                  <CondensedMedium name="AI_UNMATCHABLE_MESSAGE">
                    <Flashy bold>Note:</Flashy>{' '}
                    {opportunity.aiUnmatchableMessage}
                  </CondensedMedium>
                </Gutter>
              )}
            {opportunity.isFulfillmentActive && (
              <Gutter bottom="16px">
                <Layout direction="vertical">
                  <Button
                    name="ADD_TALENT_BUTTON"
                    variation="outline"
                    pattern="secondary"
                    onClick={addTalentOnClick}
                  >
                    Add Talent
                  </Button>
                </Layout>
              </Gutter>
            )}
            {candidates.length === 0 ? (
              <OpportunityTalentParentPositionEmpty
                isTalentPool={isTalentPool}
              />
            ) : (
              <OpportunityTalentParentPositionList
                opportunity={opportunity}
                candidates={candidates}
                isTalentPool={isTalentPool}
              />
            )}
          </CardSection>
        </OTScrollable>
      </Card>
      {showGetMoreMatchesModal && (
        <GetMoreMatchesModal
          opportunity={opportunity}
          onRequestSuccess={({ filters }) => {
            setSavedFilters(filters);
          }}
          onClose={() => setShowGetMoreMatchesModal(false)}
          savedFilters={savedFilters}
        />
      )}
    </>
  );
};
