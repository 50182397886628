import { arrayOf, number, shape, string } from 'prop-types';

import FormStore from '../classes/form-store';
import { saveSearch } from '../api/searches';
import { AjvUtil } from '../utils/ajv-util';
import { SavedFilterShapeStructure } from '../models/saved-filters';

import { UserCandidateFiltersStore } from './user-candidate-filters-store';

const SavedCandidateFilterValidation = AjvUtil.compileSchema({
  required: ['name'],
  properties: {
    name: {
      type: 'string',
      maxLength: 255,
      minLength: 1,
    },
  },
});

class EditSavedCandidateFilterStoreClass extends FormStore {
  validate(data) {
    return AjvUtil.formatValidationForFinalForm(
      SavedCandidateFilterValidation,
      data
    );
  }

  load(userId, filtersData) {
    this.setState({
      userId,
      filtersData,
    });
  }

  submit(userId, filterConfig) {
    const sendFilters = {
      type: UserCandidateFiltersStore.getType(),
      id: filterConfig.id,
      name: filterConfig.name,
      search: filterConfig.filters,
    };

    return this.clearState(
      saveSearch(userId, sendFilters).then(data => {
        UserCandidateFiltersStore.load(userId);

        return data.id;
      })
    );
  }

  getDataShape() {
    return shape(
      SavedFilterShapeStructure(
        shape({
          search: string,
          axiomEmployeeType: arrayOf(string),
          barredLocations: arrayOf(string),
          employeeType: arrayOf(string),
          homeOfficeId: arrayOf(string),
          languages: arrayOf(string),
          ownerUserId: arrayOf(string),
          practiceAreaId: arrayOf(string),
          profileStatus: arrayOf(string),
          tags: arrayOf(string),
          compensation: {
            start: number,
            end: number,
          },
          weeklyAvailability: {
            start: number,
            end: number,
          },
          yearsOfExperience: {
            start: number,
            end: number,
          },
          daysToSoonestEngagementEnd: number,
          industries: arrayOf(string),
        })
      )
    );
  }
}

export const EditSavedCandidateFilterStore =
  new EditSavedCandidateFilterStoreClass();
