import React, { Component } from 'react';
import { bool, func, shape } from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  ContextMenu,
  ContextMenuItem,
  Gutter,
  Layout,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalSection,
  Paragraph,
  ReadonlyTextarea,
} from '@axiom/ui';
import { isEnvoyAdmin, isEnvoyUser } from '@axiom/utils';

import UnorderedList from '../UnorderedList/UnorderedList';
import { experienceShape } from '../../models/experience';
import { DateUtil } from '../../utils/date-util';
import { sortByName } from '../../utils/sort';
import { FormTalentExperienceStore } from '../../stores/form-talent-experience-store';
import { PreloadedUserStore } from '../../stores/preloaded-user-store';
import { ConvertToAxiomExperience } from '../ConvertToAxiomExperience/ConvertToAxiomExperience';
import { ExperiencesApi } from '../../api/experiences';
import { CandidateExperiencesApi } from '../../api/candidate-experiences';
import { CandidateIndustriesApi } from '../../api/candidate-industries';

import {
  CandidateExperienceItemWrapper,
  CEICard,
  CEICardBody,
  CEICardRow,
  CEICardRowLeft,
  CEICardRowRight,
  CEICardRowRightMenu,
  CEIStartEndDates,
  CEIDateTime,
  CEICardFooter,
  CEICardDrawer,
  CEILabel,
} from './CandidateExperienceItemStyles';

class CandidateExperienceItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmingDelete: false,
      isConvertingExperience: false,
    };
  }

  cancelDelete = () => {
    this.setState({ isConfirmingDelete: false });
  };

  confirmDelete = async () => {
    const { experience } = this.props;
    await ExperiencesApi.deleteExperience(experience.id);
    await CandidateExperiencesApi.refreshExperiences(experience.candidateId);
    await CandidateIndustriesApi.refreshIndustries(experience.candidateId);
  };

  startEditing = () => {
    FormTalentExperienceStore.load(
      this.props.experience.candidateId,
      this.props.experience
    );
  };

  toggleExpanded = () => {
    this.props.toggleExpanded(this.props.experience.id);
  };

  formatEndDate = (endDate, isAxiom) => {
    if (DateUtil.castAsMoment(endDate).isSameOrAfter(new Date())) {
      return 'Present';
    }

    return isAxiom
      ? DateUtil.displayDate(endDate)
      : DateUtil.displayMonthYear(endDate);
  };

  render() {
    const { expanded, experience, user, taxonomy } = this.props;
    if (!experience || !experience.id) return null;

    const { isConfirmingDelete, isConvertingExperience } = this.state;

    const {
      client,
      endDate,
      externalOpportunityName,
      isAxiom,
      locationName,
      startDate,
      description,
      opportunity,
      industryValue,
      id,
    } = experience;

    return (
      <CandidateExperienceItemWrapper
        data-test="EXPERIENCELOGITEM"
        data-value={client}
      >
        <CEICard>
          <CEICardBody>
            <CEICardRow>
              <CEICardRowLeft>
                <UnorderedList title="Company" values={client} />
              </CEICardRowLeft>
              <CEICardRowRight>
                <UnorderedList
                  title="Date"
                  values={
                    <CEIStartEndDates>
                      <CEIDateTime dateTime={startDate}>
                        {isAxiom
                          ? DateUtil.displayDate(startDate)
                          : DateUtil.displayMonthYear(startDate)}
                      </CEIDateTime>
                      <span> - </span>
                      {endDate ? (
                        <CEIDateTime dateTime={endDate}>
                          {this.formatEndDate(endDate, isAxiom)}
                        </CEIDateTime>
                      ) : (
                        <span>Present</span>
                      )}
                    </CEIStartEndDates>
                  }
                />
                <CEICardRowRightMenu>
                  <ContextMenu direction="left">
                    <ContextMenuItem
                      name="TALENT_EXPERIENCE_CONTEXT_MENU_ITEM_EDIT"
                      onClick={() => {
                        this.startEditing();
                      }}
                    >
                      Edit
                    </ContextMenuItem>
                    <ContextMenuItem
                      name="TALENT_EXPERIENCE_CONTEXT_MENU_ITEM_DELETE"
                      onClick={() => {
                        this.setState({ isConfirmingDelete: true });
                      }}
                    >
                      Delete
                    </ContextMenuItem>
                    {!isAxiom && (isEnvoyUser(user) || isEnvoyAdmin(user)) && (
                      <ContextMenuItem
                        name="TALENT_EXPERIENCE_CONTEXT_MENU_ITEM_CONVERT"
                        onClick={() => {
                          this.setState({ isConvertingExperience: true });
                        }}
                      >
                        Convert
                      </ContextMenuItem>
                    )}
                  </ContextMenu>
                </CEICardRowRightMenu>
              </CEICardRowRight>
            </CEICardRow>
            <CEICardRow>
              <UnorderedList
                title={isAxiom ? 'Opportunity' : 'Job Title'}
                values={isAxiom ? opportunity.jobName : externalOpportunityName}
                dataValue={id}
              />
            </CEICardRow>
          </CEICardBody>
          <CEICardFooter>
            {expanded && (
              <CEICardDrawer>
                <CEICardRow>
                  <UnorderedList
                    title="Location"
                    values={
                      isAxiom
                        ? opportunity.locationCityStateCountry
                        : locationName
                    }
                  />
                  <CEICardRowRight>
                    <UnorderedList
                      title="Industry"
                      values={taxonomy.industries[industryValue]?.displayValue}
                    />
                  </CEICardRowRight>
                </CEICardRow>
                {isAxiom && (
                  <CEICardRow>
                    <CEICardRowRight>
                      <UnorderedList
                        title="Site Preference"
                        values={opportunity.position?.worksite}
                      />
                    </CEICardRowRight>
                  </CEICardRow>
                )}
                <CEICardRow>
                  <div>
                    <CEILabel>Description</CEILabel>
                    <ReadonlyTextarea value={description || '--'} />
                  </div>
                </CEICardRow>
              </CEICardDrawer>
            )}
          </CEICardFooter>
          <Gutter bottom="8px" />
          <Layout position="center">
            <Button
              aria-pressed={expanded}
              onClick={this.toggleExpanded}
              data-test="EXPAND_BUTTON"
              icon={expanded ? 'arrow-up' : 'arrow-down'}
              pattern="secondary"
              variation="minimal"
            >
              Show {expanded ? 'less' : 'more'}
            </Button>
          </Layout>
        </CEICard>

        {isConfirmingDelete && (
          <Modal size="confirm" name="CONFIRMEXPDELETE">
            <ModalHeader onClose={this.cancelDelete}>
              Delete "{isAxiom ? opportunity.jobName : externalOpportunityName}"
            </ModalHeader>
            <ModalSection>
              <Paragraph>
                You have chosen to delete this experience. Doing so will remove
                it from Axiom For Talent as well. Are you sure you would like to
                proceed?
              </Paragraph>
            </ModalSection>
            <ModalFooter>
              <Button variation="outline" onClick={this.cancelDelete}>
                Cancel
              </Button>
              <Button onClick={this.confirmDelete}>Delete</Button>
            </ModalFooter>
          </Modal>
        )}
        {isConvertingExperience && (
          <ConvertToAxiomExperience
            experience={experience}
            onClose={() => this.setState({ isConvertingExperience: false })}
          />
        )}
      </CandidateExperienceItemWrapper>
    );
  }
}

CandidateExperienceItem.defaultProps = {
  expanded: false,
};

CandidateExperienceItem.propTypes = {
  expanded: bool,
  experience: experienceShape.isRequired,
  user: PreloadedUserStore.getDataShape().isRequired,
  toggleExpanded: func.isRequired,
  taxonomy: shape({ industries: shape({}) }).isRequired,
};

export default connect(state => ({
  user: PreloadedUserStore.select(state),
}))(CandidateExperienceItem);
