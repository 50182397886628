import React from 'react';
import { Certification } from '@axiom/const';
import { Candidate, SubmissionCandidate } from '@axiom/validation';

import { SectionHeader } from '../../content/SectionHeader/SectionHeader';
import { Gutter } from '../../layout/Gutter/Gutter';
import { Paragraph } from '../../content/Paragraph/Paragraph';
import { ParagraphHeader } from '../../content/ParagraphHeader/ParagraphHeader';

const { QUALIFICATION_AWARDS } = Certification;

export type TalentCertificationsTypes = {
  candidate: SubmissionCandidate | Candidate;
};

export const TalentCertifications = ({
  candidate,
}: TalentCertificationsTypes) => {
  const awardTypes = Object.values(QUALIFICATION_AWARDS);
  const certifications = (candidate?.certifications || []).filter(
    cert => !awardTypes.includes(cert.award)
  );

  return certifications.length > 0 ? (
    <>
      <SectionHeader name="Certifications_Awards">
        Certifications & Awards
      </SectionHeader>
      <Gutter bottom="12px" />
      {certifications.map(({ award, institution, year }, index) => (
        <div
          data-test="CERTIFICATION_AWARD"
          key={`${award}_${institution}_${year}`}
        >
          <ParagraphHeader name="AWARD_NAME">{award}</ParagraphHeader>

          <span>
            <Paragraph name="INSTITUTION" as="span">
              {institution}
            </Paragraph>
            ,{' '}
            <Paragraph name="YEAR" as="span">
              {year}
            </Paragraph>
          </span>
          {index < certifications.length - 1 && <Gutter bottom="8px" />}
        </div>
      ))}
    </>
  ) : null;
};
