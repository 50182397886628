import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { capitalize } from 'lodash';
import { connect } from 'react-redux';
import { number, string, bool } from 'prop-types';
import {
  Banner,
  Button,
  CheckmarkCircleIcon,
  DarkTheme,
  DataTest,
  FluidButtonLayout,
  Gutter,
  Header6,
  IconButton,
} from '@axiom/ui';

import EmptyDescription from '../EmptyDescription/EmptyDescription';
import StoreStateTreatment from '../StoreStateTreatment/StoreStateTreatment';
import { positionShape } from '../../models/position';
import { DateUtil } from '../../utils/date-util';
// eslint-disable-next-line boundaries/element-types
import { ULAftIcon } from '../UnorderedList/UnorderedListStyles';
import { PositionUtils } from '../../utils/position-utils';
import { NumberUtil } from '../../utils/number-util';
import { EditPositionStore } from '../../stores/edit-position-store';
import { DeletePositionStore } from '../../stores/delete-position-store';
import DropdownTree from '../DropdownTree/DropdownTree';
import { formatDataTestId } from '../../utils/dataTest';
import { OpportunityProfilePositionContextMenu } from '../OpportunityProfilePositionContextMenu/OpportunityProfilePositionContextMenu';

import {
  OpportunityProfilePositionWrapper,
  OPPOverlay,
  OPPOverlayForm,
  OPPOverlayFormInput,
  OPPUnderlay,
  OPPHeader,
  OPPIcon,
  OPPHeaderContent,
  OPPMainContent,
  OPPRow,
  OPPTitles,
  OPPTitle,
  OPPValues,
  OPPCell,
  OPPDisplayWrapper,
  OPPDivider,
  OPPRowWrapper,
  OPPRichTextEditor,
} from './OpportunityProfilePositionsStyled';

const OpportunityProfilePosition = ({
  position,
  currency,
  displayIndex,
  editPositionState,
  isFulfillmentActive,
}) => {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [openEditDesc, setOpenEditDesc] = useState(false);
  const [description, setDescription] = useState(position.description);
  const [isDescriptionFocused, setDescriptionFocus] = useState(false);
  const [isLastOpen, setIsLastOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const descriptionRef = useRef(null);
  const editDescRef = useRef(null);

  useEffect(() => {
    if (searchParams.get('showMoreEnabled') === position.id) {
      setShowMore(true);
      searchParams.delete('showMoreEnabled');
      setSearchParams(searchParams, { replace: true });
    }

    if (searchParams.get('editPositionDescription') === position.id) {
      setShowMore(true);
      setOpenEditDesc(true);
      searchParams.delete('editPositionDescription');
      setSearchParams(searchParams, { replace: true });
      EditPositionStore.load(position);
    }
  }, [searchParams]);

  useEffect(() => {
    if (showMore && !openEditDesc) {
      descriptionRef?.current?.scrollIntoView?.({ block: 'end' });
    }
  }, [showMore]);

  useEffect(() => {
    const hasLoadedEditDesc =
      EditPositionStore.isRenderingLoadedView(editPositionState);

    if (hasLoadedEditDesc && showMore && openEditDesc) {
      editDescRef?.current?.scrollIntoView?.({
        block: 'end',
        behavior: 'smooth',
      });
    }
  }, [editPositionState]);

  const handleClickShowMore = () => setShowMore(!showMore);

  const handleDescEditSave = data => {
    setOpenEditDesc(false);
    EditPositionStore.save({
      ...data,
      description,
    });
  };

  const handleDescEditCancel = () => {
    setOpenEditDesc(false);
    setDescription(position.description);
    EditPositionStore.clearState();
  };

  const showOverlay = () => {
    setIsOverlayVisible(true);
  };

  const hideOverlay = () => {
    setIsOverlayVisible(false);
  };

  const handleOverlaySubmit = e => {
    e.preventDefault();
    EditPositionStore.save({
      id: position.id,
      opportunityId: position.opportunityId,
      name: new FormData(e.target).get('POSITION_NAME'),
    });
    hideOverlay();
  };

  const buildOnDropdownVisibilityChange = isLastInstance => () =>
    isLastInstance ? setIsLastOpen(!isLastOpen) : null;

  return (
    <OpportunityProfilePositionWrapper extraMargin={isLastOpen}>
      <OPPUnderlay
        shouldBlur={isOverlayVisible}
        data-test={formatDataTestId('Position')}
      >
        <DarkTheme backgroundName="none">
          <OPPHeader>
            <OPPIcon>
              <CheckmarkCircleIcon
                checked={PositionUtils.isPositionFulfilled(position.candidates)}
                data-test={formatDataTestId('CheckmarkCircleIcon')}
                filled
              />
            </OPPIcon>

            <OPPHeaderContent>
              <Header6>Position {displayIndex}</Header6>
              <DataTest value="Name">{position.name}</DataTest>
            </OPPHeaderContent>

            <OpportunityProfilePositionContextMenu
              showEditNameOverlay={showOverlay}
              showDeletePositionModal={() => DeletePositionStore.load(position)}
              isFulfillmentActive={isFulfillmentActive}
            />
          </OPPHeader>
        </DarkTheme>
        <OPPMainContent data-test={formatDataTestId('OppMainContent')}>
          <OPPRow>
            <OPPTitles>
              <OPPTitle size={4}>
                Role
                <ULAftIcon />
              </OPPTitle>
              <OPPTitle size={4}>
                Start Date
                <ULAftIcon />
              </OPPTitle>
              <OPPTitle size={4}>
                End Date
                <ULAftIcon />
              </OPPTitle>
            </OPPTitles>
            <OPPValues>
              <OPPCell
                size={4}
                title={position.role}
                data-test={formatDataTestId('Role')}
              >
                {position.role}
              </OPPCell>
              <OPPCell
                size={4}
                title={DateUtil.displayDate(position.startDate)}
                data-test={formatDataTestId('OpportunityPositionStartDate')}
              >
                {DateUtil.displayDate(position.startDate)}
              </OPPCell>
              <OPPCell
                size={4}
                title={DateUtil.displayDate(position.endDate)}
                data-test={formatDataTestId('opportunityPositionEndDate')}
              >
                {DateUtil.displayDate(position.endDate)}
              </OPPCell>
            </OPPValues>
          </OPPRow>
          <OPPRow>
            <OPPTitles>
              <OPPTitle size={4}>Reserved Type</OPPTitle>
              <OPPTitle size={4}>
                Billing Unit
                <ULAftIcon />
              </OPPTitle>
              <OPPTitle size={4}>
                Units per Week
                <ULAftIcon />
              </OPPTitle>
              <OPPTitle size={4}>
                Worksite
                <ULAftIcon />
              </OPPTitle>
            </OPPTitles>
            <OPPValues>
              <OPPCell
                size={4}
                title={position.reservedType}
                data-test={formatDataTestId('ReservedType')}
              >
                {position.reservedType}
              </OPPCell>
              <OPPCell
                size={4}
                title={capitalize(position.weeklyTimeUnit)}
                data-test={formatDataTestId('WeeklyTimeUnit')}
              >
                {capitalize(position.weeklyTimeUnit)}
              </OPPCell>
              <OPPCell
                size={4}
                title={position.billingUnitsPerWeek}
                data-test={formatDataTestId('BillingUnitsPerWeek')}
              >
                {position.billingUnitsPerWeek}
              </OPPCell>
              <OPPCell
                size={4}
                title={position.worksite}
                data-test={formatDataTestId('WorkSite')}
              >
                {position.worksite}
              </OPPCell>
            </OPPValues>
          </OPPRow>
          <OPPDisplayWrapper
            style={{ display: showMore ? 'block' : 'none' }}
            data-test={formatDataTestId('closed_Section')}
            data-value={showMore ? 'open' : 'closed'}
          >
            <OPPDivider />
            <OPPRow>
              <OPPTitles>
                <OPPTitle size={3}>Talent Experience</OPPTitle>
                <OPPTitle size={3}>Talent Type</OPPTitle>
              </OPPTitles>
              <OPPValues>
                <OPPCell
                  size={3}
                  title={position.talentExperienceYears}
                  data-test={formatDataTestId('TalentExperienceYears')}
                >
                  {position.talentExperienceYears}
                </OPPCell>
                <OPPCell
                  size={3}
                  title={position.talentType}
                  data-test={formatDataTestId('TalentType')}
                >
                  {position.talentType}
                </OPPCell>
              </OPPValues>
            </OPPRow>
            <OPPRow>
              <OPPTitles>
                <OPPTitle size={3}>Baseline Cost</OPPTitle>
                <OPPTitle size={3}>Baseline Margin</OPPTitle>
                <OPPTitle size={3}>Baseline List Price</OPPTitle>
              </OPPTitles>
              <OPPValues>
                <OPPCell
                  size={3}
                  title={`${NumberUtil.formatCurrency(
                    position.baselineCost,
                    currency
                  )}`}
                  data-test={formatDataTestId('BaselineCost')}
                >
                  {NumberUtil.formatCurrency(position.baselineCost, currency)}
                </OPPCell>
                <OPPCell
                  size={3}
                  title={position.baselineMargin}
                  data-test={formatDataTestId('BaselineMargin')}
                >
                  {position.baselineMargin}%
                </OPPCell>
                <OPPCell
                  size={3}
                  title={`${NumberUtil.formatCurrency(
                    position.baselineBillRate,
                    currency
                  )}`}
                  data-test={formatDataTestId('BaselineBillRate')}
                >
                  {NumberUtil.formatCurrency(
                    position.baselineBillRate,
                    currency
                  )}
                </OPPCell>
              </OPPValues>
            </OPPRow>
            {position.aiDescription &&
              position.description.includes(position.aiDescription) && (
                <OPPRow>
                  <Banner>
                    This auto generated position description received a high
                    rating. Any AI matches found will be shown in the “Talent”
                    tab.
                  </Banner>
                </OPPRow>
              )}
            <OPPRow>
              <OPPRowWrapper>
                <OPPTitle>
                  Position Description
                  <ULAftIcon />
                </OPPTitle>
                {!EditPositionStore.isRenderingLoadedView(
                  editPositionState
                ) && (
                  <IconButton
                    pattern="secondary"
                    variation="minimal"
                    icon="pencil"
                    name="EDITDESCRIPTION"
                    onClick={() => EditPositionStore.load(position)}
                  />
                )}
              </OPPRowWrapper>
            </OPPRow>
            <StoreStateTreatment
              dataId={position.id}
              dataIdName="id"
              storeState={editPositionState}
              renderNonLoadedView={() => (
                <OPPRow ref={descriptionRef}>
                  {position.description ? (
                    <OPPRichTextEditor
                      key={position.description}
                      initialValue={position.description}
                      id={position.id}
                      readOnly
                    />
                  ) : (
                    <EmptyDescription header="No Position Description" />
                  )}
                </OPPRow>
              )}
              renderLoadedView={({ data: editPosition }) => (
                <OPPRow ref={editDescRef}>
                  <OPPRichTextEditor
                    onClick={() => setDescriptionFocus(true)}
                    onBlur={() => setDescriptionFocus(false)}
                    isFocused={isDescriptionFocused}
                    key={editPosition.description}
                    onChange={setDescription}
                    initialValue={description}
                    id={editPosition.id}
                  />
                  <Gutter bottom="16px" />
                  <FluidButtonLayout>
                    <Button
                      className="edit-action save"
                      onClick={handleDescEditCancel}
                      name="EDITDESCRIPTIONCANCEL"
                      variation="outline"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="edit-action save"
                      onClick={() => handleDescEditSave(editPosition)}
                      name="EDITDESCRIPTIONSAVE"
                    >
                      Save
                    </Button>
                  </FluidButtonLayout>
                </OPPRow>
              )}
            />
          </OPPDisplayWrapper>
          <Button
            pattern="secondary"
            variation="minimal"
            fluid
            className="gtm-show-more-less"
            onClick={handleClickShowMore}
            name="SHOWMORE"
            icon={showMore ? 'arrow-up' : 'arrow-right'}
          >
            {showMore ? 'Show Less' : 'Show More'}
          </Button>
          <Gutter bottom="16px" />
        </OPPMainContent>
      </OPPUnderlay>
      {isOverlayVisible && (
        <OPPOverlay>
          <OPPOverlayForm onSubmit={handleOverlaySubmit}>
            <OPPOverlayFormInput
              name="POSITION_NAME"
              label="Position Name"
              dataTest="editInput"
              id={`opportunityProfilePositionNameInput-${position.opportunityId}-${position.id}`}
              defaultValue={position.name}
            />
            <Gutter bottom="16px" />
            <FluidButtonLayout>
              <Button
                onClick={hideOverlay}
                name="EDITCANCEL"
                variation="outline"
              >
                Cancel
              </Button>
              <Button text="Submit" type="submit" name="EDITSAVE">
                Submit
              </Button>
            </FluidButtonLayout>
          </OPPOverlayForm>
        </OPPOverlay>
      )}
    </OpportunityProfilePositionWrapper>
  );
};

OpportunityProfilePosition.defaultProps = {};

OpportunityProfilePosition.propTypes = {
  editPositionState: EditPositionStore.getStateShape().isRequired,
  displayIndex: number.isRequired,
  position: positionShape.isRequired,
  currency: string.isRequired,
  isFulfillmentActive: bool.isRequired,
};

export default connect(state => ({
  editPositionState: EditPositionStore.select(state),
}))(OpportunityProfilePosition);
