import React, { useState } from 'react';
import {
  Button,
  DateInput,
  Dropdown,
  FluidButtonLayout,
  Form,
  Grid,
  GridColumn,
  GridRow,
  Gutter,
  FormGroup,
  Input,
  Layout,
  useApi,
  Tooltip,
  DateUtil,
  TaxonomyUtil,
} from '@axiom/ui';
import { PracticeAreaConst } from '@axiom/const';
import {
  BarredLocation,
  HomeOffice,
  PracticeArea,
  Tag,
} from '@axiom/validation';

import { PracticeAreasApi } from '../../api/practiceAreas';
import { EnvUtil } from '../../utils/env-util';
import { toTwoLayerDropdownTree } from '../../utils/dropdown-tree-utils';
import { PARALEGAL } from '../../api/constants';
import { TagsApi } from '../../api/tags';
import { BarredLocationApi } from '../../api/barred-locations';
import { HomeOfficeApi } from '../../api/homeOffices';
import { LanguagesApi } from '../../api/languages';
import { UsersApi } from '../../api/users';
import { TaxonomyApi } from '../../api/taxonomy';
import {
  CandidateFiltersSchema,
  CandidateFiltersForm as CandidateFiltersFormType,
} from '../../api/candidate/candidate-filters-schema';
import { CandidateUtil } from '../../utils/candidate-util';
import { WindowUtil } from '../../utils/window-util';

import {
  talentProfileStatusSelectItems,
  jobProfileOptions,
  employeeTypeOptions,
  workPreferencesOptions,
} from './CandidateFiltersFormConstants';
import {
  CandidateFiltersSaveFormModal,
  FormSchema as SaveFormSchema,
} from './CandidateFiltersSaveFormModal';

function getPracticeAreas(list: Array<PracticeArea>) {
  list = list.filter(
    pa => pa.type && pa.name !== PracticeAreaConst.PracticeAreas.Unknown
  );

  const sortedData = toTwoLayerDropdownTree(list);
  const entries: { id: string; name: string }[] = [];

  sortedData.forEach(data => {
    const { branch, id, name } = data;

    if (branch) {
      entries.push({
        id,
        name: `${branch} - ${name}`,
      });
    } else {
      entries.push({
        id,
        name,
      });
    }
  });
  return entries;
}

const yearsOfExperienceMapper = [
  { name: 'ZeroToThree', obj: { start: 0, end: 3 } },
  { name: 'FourToSeven', obj: { start: 4, end: 7 } },
  { name: 'EightToEleven', obj: { start: 8, end: 11 } },
  { name: 'TwelveToFifteen', obj: { start: 12, end: 15 } },
  { name: 'SixteenToNineteen', obj: { start: 16, end: 19 } },
  { name: 'TwentyPlus', obj: { start: 20 } },
];

function getBarredLocationOptions(barredLocations: Array<BarredLocation>) {
  const options = barredLocations.map(bl => ({
    label: bl.name,
    value: bl.id,
  }));
  // Add paralegal option
  options.push({ label: PARALEGAL, value: PARALEGAL });

  return options.sort(({ label: nameA }, { label: nameB }) => {
    const textA = nameA.toUpperCase();
    const textB = nameB.toUpperCase();
    if (textA < textB) return -1;
    if (textA > textB) return 1;
    return 0;
  });
}
function getInitialRollOffDatePickerValue(daysToSoonestEngagementEnd: number) {
  if (!daysToSoonestEngagementEnd || daysToSoonestEngagementEnd < 0) {
    return 0;
  }
  return daysToSoonestEngagementEnd;
}
function getInitialRollOffDateDropDownValue(
  daysToSoonestEngagementEnd: number
) {
  if (!daysToSoonestEngagementEnd && daysToSoonestEngagementEnd !== 0) {
    return null;
  }
  return [null, 15, 30, 45].includes(daysToSoonestEngagementEnd)
    ? daysToSoonestEngagementEnd
    : 0;
}
function getHomeOfficeOptions(homeOffices: Array<HomeOffice>) {
  const homeOfficeOptions: {
    options: { label: string; value: string[] }[];
    groups: Map<string, string[]>;
  } = { options: [], groups: new Map<string, string[]>() };

  homeOffices.forEach(office => {
    const { categories } = office;
    const officeOption = { label: office.name, value: [office.id] };

    homeOfficeOptions.options.push(officeOption);
    if (categories.length > 0) {
      categories.forEach(category => {
        const groups = homeOfficeOptions.groups.get(category) || [];
        groups.push(office.id);
        homeOfficeOptions.groups.set(category, groups);
      });
    }
  });

  homeOfficeOptions.groups.forEach((value: string[], key: string) => {
    homeOfficeOptions.options.push({ label: key, value });
  });

  return homeOfficeOptions.options.sort((a, b) => (a.label > b.label ? 1 : -1));
}

export const CandidateFiltersForm = ({
  handleFilterChange = () => {},
  handleSaveFilters = () => {},
  filters,
  isEditable,
}: {
  filters: CandidateFiltersFormType;
  handleFilterChange?: (filters: CandidateFiltersFormType) => void;
  handleSaveFilters?: (
    saveFilters: SaveFormSchema & { filters: CandidateFiltersFormType }
  ) => void;
  isEditable: boolean;
}) => {
  // We just wanna store the initial state so that followup changes dont
  // affect the form
  const [talentFilters, setTalentFilters] = useState<CandidateFiltersFormType>({
    ...filters,
    rollOffDateDropdown: getInitialRollOffDateDropDownValue(
      filters.daysToSoonestEngagementEnd
    ),
    rollOffDatePicker: DateUtil.addDaysToNow(
      getInitialRollOffDatePickerValue(filters.daysToSoonestEngagementEnd)
    ),
  });
  const [
    { data: practiceAreas },
    { data: languages },
    { data: homeOffices },
    { data: tags },
    { data: ownerUsers },
    { data: barredLocations },
    { data: taxonomy },
  ] = useApi(
    PracticeAreasApi.readPracticeAreas(),
    LanguagesApi.readLanguages(),
    HomeOfficeApi.readHomeOffices(),
    TagsApi.readTags(),
    UsersApi.readUsers(),
    BarredLocationApi.readBarredLocations(),
    TaxonomyApi.readTaxonomy()
  );
  const industryOptions = TaxonomyUtil.getIndustryOptions(taxonomy.industries);

  const [showSaveCandidateFiltersModal, setShowSaveCandidateFiltersModal] =
    useState(false);

  const getYearsOfExperience = (e: string) => {
    if (e) {
      const { obj } = yearsOfExperienceMapper.find(o => o.name === e);
      return obj || undefined;
    }
  };

  const setYearsOfExperienceFromRadio = (
    setForm: (values: Record<string, unknown>) => void,
    values: Record<string, unknown>,
    value: string
  ) => {
    const newValues = getYearsOfExperience(value);
    if (newValues) {
      setForm({
        ...values,
        yearsOfExperience: {
          start: newValues.start,
          end: newValues.end,
        },
      });
    }
  };

  return (
    <div>
      <Form
        name="TALENT_FILTERS_FORM"
        initialValues={talentFilters}
        schema={CandidateFiltersSchema}
        submitOnChange
        onSubmit={async changedData => {
          const resultingChanges: CandidateFiltersFormType = {
            ...filters,
            ...Object.keys(changedData).reduce(
              (acc, propName) => {
                const propValue =
                  changedData[propName as keyof typeof CandidateFiltersForm];
                acc[propName] = propValue === undefined ? null : propValue;

                return acc;
              },
              {} as Record<string, unknown>
            ),
          };

          // These are needed by the form but shouldn't
          // be included in the filters
          delete resultingChanges.rollOffDatePicker;
          delete resultingChanges.rollOffDateDropdown;

          handleFilterChange(resultingChanges);
        }}
      >
        {({ values, setValues }) => {
          return (
            <>
              <br />
              <Input
                placeholder="Search"
                name="search"
                disabled={!isEditable}
                icon="search"
              />
              <Layout position="right">
                <Button
                  name="searchByCompanyExperience"
                  icon="tableau"
                  pattern="secondary"
                  variation="minimal"
                  toTab={EnvUtil.tableauCompanyUrl}
                  title="Open Tableau to find talent with experience at specific companies"
                >
                  Search by company experience
                </Button>
              </Layout>
              <Gutter bottom="24px" />
              <Dropdown
                label="Profile Status"
                name="profileStatus"
                options={talentProfileStatusSelectItems}
                placeholder="Show All"
                displayKey="label"
                valueKey="value"
                disabled={!isEditable}
              />
              <Gutter bottom="24px" />
              <FormGroup label="Current Availability (Hours)">
                <Grid columns={2}>
                  <GridRow>
                    <GridColumn>
                      <Input
                        label="From"
                        name="weeklyAvailability.start"
                        disabled={!isEditable}
                        placeholder="0"
                      />
                    </GridColumn>
                    <GridColumn>
                      <Input
                        label="To"
                        name="weeklyAvailability.end"
                        disabled={!isEditable}
                        placeholder="80"
                      />
                    </GridColumn>
                  </GridRow>
                </Grid>
              </FormGroup>
              <Gutter bottom="24px" />
              <FormGroup>
                <Grid columns={2}>
                  <GridRow>
                    <GridColumn>
                      <Dropdown
                        label="Roll Off Date"
                        name="rollOffDateDropdown"
                        options={[
                          { label: 'Any Date', value: null },
                          { label: 'Next 15 Days', value: 15 },
                          { label: 'Next 30 Days', value: 30 },
                          { label: 'Next 45 Days', value: 45 },
                          { label: 'Custom', value: 0 },
                        ]}
                        placeholder="Any Date"
                        displayKey="label"
                        valueKey="value"
                        disabled={!isEditable}
                        onChange={(value: number) => {
                          switch (true) {
                            case value === 0: {
                              setValues({
                                ...values,
                                daysToSoonestEngagementEnd: value,
                                rollOffDateDropdown: value,
                                rollOffDatePicker: DateUtil.addDaysToNow(0),
                              });
                              break;
                            }

                            case value === null: {
                              setValues({
                                ...values,
                                daysToSoonestEngagementEnd: value,
                                rollOffDateDropdown: value,
                                rollOffDatePicker: null,
                              });
                              break;
                            }

                            default: {
                              setValues({
                                ...values,
                                daysToSoonestEngagementEnd: value,
                                rollOffDateDropdown: value,
                                rollOffDatePicker: null,
                              });
                            }
                          }
                        }}
                      />
                    </GridColumn>
                    {values.rollOffDateDropdown === 0 && (
                      <GridColumn>
                        <DateInput
                          name="rollOffDatePicker"
                          label="Rolling Off On/Before"
                          disabled={!isEditable}
                          onChange={(value: string) => {
                            setValues({
                              ...values,
                              daysToSoonestEngagementEnd:
                                DateUtil.getDaysFromNow(value),
                              rollOffDatePicker: value,
                            });
                          }}
                        />
                      </GridColumn>
                    )}
                  </GridRow>
                </Grid>
              </FormGroup>
              <Gutter bottom="24px" />
              <FormGroup
                label="Total Annual Compensation"
                Tooltip={
                  <Tooltip>Enter salary in the local currency format</Tooltip>
                }
              >
                <Grid columns={2}>
                  <GridRow>
                    <GridColumn>
                      <Input
                        label="From"
                        name="compensation.start"
                        disabled={!isEditable}
                        placeholder="0"
                      />
                    </GridColumn>
                    <GridColumn>
                      <Input
                        label="To"
                        name="compensation.end"
                        disabled={!isEditable}
                        placeholder="2250000"
                      />
                    </GridColumn>
                  </GridRow>
                </Grid>
              </FormGroup>
              <Gutter bottom="24px" />
              <Dropdown
                label="Practice Area/Focus"
                name="practiceAreaId"
                placeholder="Show All"
                options={getPracticeAreas(practiceAreas)}
                displayKey="name"
                valueKey="id"
                disabled={!isEditable}
              />
              <Gutter bottom="24px" />
              <Dropdown
                label="Industry"
                name="industries"
                options={industryOptions}
                placeholder="Show All"
                displayKey="label"
                valueKey="value"
                disabled={!isEditable}
              />
              <Gutter bottom="24px" />
              <Dropdown
                label="Home Office"
                name="homeOfficeId"
                options={getHomeOfficeOptions(homeOffices)}
                placeholder="Show All"
                displayKey="label"
                valueKey="value"
                disabled={!isEditable}
              />
              <Layout position="right">
                <Button
                  icon="tableau"
                  pattern="secondary"
                  variation="minimal"
                  toTab={EnvUtil.tableauLocationUrl}
                  title="Open Tableau to find talent in specific geographic areas"
                >
                  Search by talent location
                </Button>
              </Layout>
              <Gutter bottom="24px" />
              <Dropdown
                label="Bar Admission"
                name="barredLocations"
                options={getBarredLocationOptions(barredLocations)}
                placeholder="Show All"
                displayKey="label"
                valueKey="value"
                disabled={!isEditable}
              />
              <Gutter bottom="24px" />
              <FormGroup label="Years Of Experience">
                <Grid columns={2}>
                  <GridRow>
                    <GridColumn>
                      <Input
                        label="From"
                        name="yearsOfExperience.start"
                        disabled={!isEditable}
                        placeholder="0"
                      />
                    </GridColumn>
                    <GridColumn>
                      <Input
                        label="To"
                        name="yearsOfExperience.end"
                        disabled={!isEditable}
                        placeholder="50"
                      />
                    </GridColumn>
                  </GridRow>
                </Grid>
                <Gutter bottom="24px" />
                {isEditable && (
                  <Grid columns={3}>
                    <GridRow gutterBottom="8px">
                      <GridColumn>
                        <Button
                          pattern="secondary"
                          variation="outline"
                          name="ZeroToThree"
                          onClick={() =>
                            setYearsOfExperienceFromRadio(
                              setValues,
                              values,
                              'ZeroToThree'
                            )
                          }
                        >
                          0-3 Years
                        </Button>
                      </GridColumn>
                      <GridColumn>
                        <Button
                          pattern="secondary"
                          variation="outline"
                          name="FourToSeven"
                          onClick={() =>
                            setYearsOfExperienceFromRadio(
                              setValues,
                              values,
                              'FourToSeven'
                            )
                          }
                        >
                          4-7 Years
                        </Button>
                      </GridColumn>
                      <GridColumn>
                        <Button
                          pattern="secondary"
                          variation="outline"
                          name="EightToEleven"
                          onClick={() =>
                            setYearsOfExperienceFromRadio(
                              setValues,
                              values,
                              'EightToEleven'
                            )
                          }
                        >
                          8-11 Years
                        </Button>
                      </GridColumn>
                    </GridRow>
                    <GridRow>
                      <GridColumn>
                        <Button
                          pattern="secondary"
                          variation="outline"
                          name="TwelveToFifteen"
                          onClick={() =>
                            setYearsOfExperienceFromRadio(
                              setValues,
                              values,
                              'TwelveToFifteen'
                            )
                          }
                        >
                          12-15 Years
                        </Button>
                      </GridColumn>
                      <GridColumn>
                        <Button
                          pattern="secondary"
                          variation="outline"
                          name="SixteenToNineteen"
                          onClick={() =>
                            setYearsOfExperienceFromRadio(
                              setValues,
                              values,
                              'SixteenToNineteen'
                            )
                          }
                        >
                          16-19 Years
                        </Button>
                      </GridColumn>
                      <GridColumn>
                        <Button
                          pattern="secondary"
                          variation="outline"
                          name="TwentyPlus"
                          onClick={() =>
                            setYearsOfExperienceFromRadio(
                              setValues,
                              values,
                              'TwentyPlus'
                            )
                          }
                        >
                          20+ Years
                        </Button>
                      </GridColumn>
                    </GridRow>
                  </Grid>
                )}
              </FormGroup>
              <Gutter bottom="24px" />
              <Dropdown
                label="Employee Type"
                name="employeeType"
                placeholder="Show All"
                options={employeeTypeOptions}
                displayKey="label"
                valueKey="value"
                disabled={!isEditable}
              />
              <Gutter bottom="24px" />
              <Dropdown
                label="Job Profile"
                name="occupationType"
                placeholder="Show All"
                options={jobProfileOptions}
                displayKey="label"
                valueKey="value"
                disabled={!isEditable}
              />
              <Gutter bottom="24px" />
              <FormGroup>
                <Dropdown
                  label="Languages"
                  name="languages"
                  placeholder="Show All"
                  options={languages.map(language => ({
                    label: language.name,
                    value: language.id,
                  }))}
                  displayKey="label"
                  valueKey="value"
                  disabled={!isEditable}
                />
                <Layout position="right">
                  <Button
                    icon="tableau"
                    pattern="secondary"
                    variation="minimal"
                    toTab={EnvUtil.tableauLanguageUrl}
                    title="Open Tableau to find talent with multiple language skills"
                  >
                    Search for multilingual talent
                  </Button>
                </Layout>
              </FormGroup>
              <Gutter bottom="24px" />
              <Dropdown
                label="Tags"
                name="tags"
                options={tags.filter((t: Tag) => t.type === 'tag')}
                placeholder="Show All"
                displayKey="name"
                valueKey="id"
                disabled={!isEditable}
              />
              <Gutter bottom="24px" />
              <Dropdown
                label="Talent Owner"
                name="ownerUserId"
                options={ownerUsers.map(user => ({
                  label: user.fullName,
                  value: user.id,
                }))}
                placeholder="Show All"
                displayKey="label"
                valueKey="value"
                disabled={!isEditable}
              />
              <Gutter bottom="24px" />
              <Dropdown
                label="Work Preferences"
                name="workPreferences"
                options={workPreferencesOptions}
                placeholder="Show All"
                displayKey="label"
                valueKey="value"
                disabled={!isEditable}
              />
              <Gutter bottom="24px" />
              {isEditable && (
                <FluidButtonLayout>
                  <Button
                    name="RESETFILTERSBUTTON"
                    onClick={() => {
                      // Need to have this take all values in the form currently and set them to undefined,
                      // Then add the defaults back in
                      const urlProps = WindowUtil.getUrlQuery();
                      const resetFormValues = Object.keys(urlProps).reduce(
                        (acc, propName) => {
                          acc[propName] = null;
                          return acc;
                        },
                        {} as Record<string, undefined>
                      );
                      const newResetData = {
                        ...resetFormValues,
                        ...CandidateUtil.getCandidatesListFilterDefaults(),
                      };

                      setValues({
                        ...newResetData,
                        rollOffDateDropdown: null,
                        rollOffDatePicker: null,
                      });
                      setTalentFilters({
                        ...newResetData,
                      });

                      handleFilterChange(newResetData);
                    }}
                    variation="outline"
                  >
                    Reset Filters
                  </Button>
                  <Button
                    name="SAVESEARCHBUTTON"
                    onClick={() => {
                      setShowSaveCandidateFiltersModal(true);
                    }}
                    className="gtm-saved-search"
                  >
                    Save Search
                  </Button>
                </FluidButtonLayout>
              )}
            </>
          );
        }}
      </Form>
      {showSaveCandidateFiltersModal && (
        <CandidateFiltersSaveFormModal
          filters={filters}
          handleSaveFilters={handleSaveFilters}
          onClose={() => setShowSaveCandidateFiltersModal(false)}
        />
      )}
    </div>
  );
};
